<template>
  <div
    class="p-2 md:p-4"
  >
    <router-link
      :to="`/recept/${recipe.slug}`"
      class="flex flex-col h-full rounded-xl p-2 shadow-2xl bg-secondary"
    >
      <div class="relative w-full">
        <ImageLoader
          class="w-full rounded-xl"
          :path="imageUrl"
          :alt="recipe.name"
          :sizes="'(min-width: 768px) 25vw, 50vw'"
          :max="image.originalWidth"
        />
        <div v-if="showNutrition" class="absolute right-0 bottom-0 flex items-center justify-end mt-2 z-50 bg-white rounded-tl-xl bg-opacity-50 md:pl-1 md:pt-1">
          <div v-for="icon of icons" :key="icon" class="mx-1">
            <font-awesome-icon :icon="['fas', icon]" class="text-gray-700 text-xs md:text-sm"/>
          </div>
        </div>
      </div>

      <div class="h-full flex flex-col justify-between">
        <h2 class="text-center text-sm lg:text-xl font-light mt-2">{{ recipe.name }}</h2>
        <div class="flex justify-center mt-3 mb-1">
          <div class="flex items-center mx-5">
            <span class="mr-2">{{ recipe.cookTime + recipe.prepTime }}'</span>
            <font-awesome-icon :icon="['far', 'clock']" class="text-lg" />
          </div>
          <div v-if="!saved" @click.prevent="addBookmark" class="flex items-center mx-5">
            <font-awesome-icon :icon="['far', 'heart']" class="text-lg" />
          </div>
          <div v-if="saved" @click.prevent="removeBookmark" class="flex items-center mx-5">
            <font-awesome-icon :icon="['fas', 'heart']" class="text-lg" />
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import ImageLoader from '@/components/ImageLoader'
import store, { addFavorite, removeFavorite } from '../store'

export default {
  components: {
    ImageLoader
  },
  props: {
    recipe: {
      type: Object,
      required: true
    },
    showNutrition: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    saved () {
      return store.favorites.includes(this.recipe?.id)
    },
    image () {
      return this.recipe?.preview ??
        this.recipe.images[0]
    },
    imageUrl () {
      if (!this.image) return ''
      return `/api/recipes/${this.recipe.id}/images/${this.image.id}`
    },
    icons () {
      const types = this.recipe.recipeIngredients
        .map(recipeIngredient => recipeIngredient.ingredient.type)
        .filter(type => type)

      if (!types.includes('meat') && !types.includes('fish')) {
        types.push('veggie')
      }

      const mapping = {
        meat: 'drumstick-bite',
        fish: 'fish',
        lactose: 'cheese',
        veggie: 'carrot',
        gluten: 'bread-slice'
      }

      return new Set(types.map(type => mapping[type]))
    }
  },
  methods: {
    addBookmark () {
      addFavorite(this.recipe, this.$route.fullPath)
    },
    removeBookmark () {
      removeFavorite(this.recipe, this.$route.fullPath)
    }
  }
}
</script>
